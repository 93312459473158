import { getXboxInfoWebview1 } from './getXboxInfoWebview1'
import { getXboxInfoWebview2 } from './getXboxInfoWebview2'
import { isWebview2 } from './isWebview2'
import { WindowWithChromeWebviewPost, XboxAppStaticInfo } from './types'

export const getXboxInfo = async (force = false): Promise<XboxAppStaticInfo> => {
  if (!isWebview2()) {
    return {
      ...webView1Defaults,
      ...getXboxInfoWebview1(),
    }
  }
  if (force) {
    clearInfo()
  }
  return getXboxInfoWebview2()
}

function clearInfo() {
  delete (window as WindowWithChromeWebviewPost).XboxAppStaticInfo
}

// Note: this applies only to webview1
// These defaults, if needed, should be added to the real data as close to
// the point of use as possible.
const webView1Defaults: XboxAppStaticInfo = {
  appVersion: 'unknown',
  deviceFamily: 'Windows.Xbox',
  deviceModel: 'Xbox One',
  advertisingId: null,
  isLAT: true,
}
