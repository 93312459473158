import { notifyXbox } from './notifyXbox'
import { WindowWithChromeWebviewPost, XboxAppStaticInfo } from './types'

const timeoutInMs = 10000

function getXboxAppStaticInfo(): XboxAppStaticInfo | null {
  return typeof window === 'undefined' ? null : (window as WindowWithChromeWebviewPost).XboxAppStaticInfo ?? null
}

export const getXboxInfoWebview2 = async (): Promise<XboxAppStaticInfo> => {
  const info = getXboxAppStaticInfo()
  if (info) {
    return info
  }
  notifyXbox({ Message: 'UpdateAppInfo', Args: {} })
  return new Promise<XboxAppStaticInfo>((resolve, reject) => {
    const startTime = new Date().getTime()
    const id = setInterval(() => {
      const info = getXboxAppStaticInfo()
      if (info) {
        clearInterval(id)
        resolve(info)
      } else if (new Date().getTime() - startTime > timeoutInMs) {
        clearInterval(id)
        reject('unable to find webview2 information')
      }
    }, 250)
  })
}
