import { DeviceType } from '@grandstand/presentation-models'
import { useState } from 'react'
import UAParser from 'ua-parser-js'
import { v4 as uuidv4 } from 'uuid'
import { ItemStorage } from '../newPackages/StorageProviders/localStorageProvider'
import { isConnectedWeb } from '../utils/appUtils'
import { Logger } from '../utils/logger'
import { getXboxInfo } from '../utils/xboxUtils/getXboxInfo'
import { useLocalStorage } from './useLocalStorage'

export type DeviceInfo = {
  device_name: string
  device_id: string
  device_type: DeviceType
}

export type CurrentDevice = DeviceInfo & {
  navigator: Navigator | null
  user_agent: string | null
  device_platform: ConnectedDevicePlatform
  device_is_safari: boolean
}

const generateDeviceId = (): string => {
  return uuidv4()
}

const DEVICE_ID_KEY = 'bally_device_id'

const getNavigator = () => window.navigator ?? navigator ?? null

export const getUserAgent = () => getNavigator()?.userAgent ?? null

export const createUAParser = () => {
  const userAgent = getUserAgent()
  return new UAParser(userAgent)
}

export type ConnectedDevicePlatform = Extract<DeviceType, 'tv_xboxone' | 'tv_samsung' | 'tv_generic'>

export const getConnectedDevicePlatform = (): ConnectedDevicePlatform => {
  const cwOnBrowserStorage = new ItemStorage('cwOnBrowser', false)
  const cwOnBrowser = cwOnBrowserStorage.getItem()
  if (typeof window === 'undefined') {
    return 'tv_generic'
  }
  const ua = getUserAgent().toLowerCase()
  const win = window as Window & {
    tizen?: any
    Windows?: any
    chrome?: any
  }
  if (isConnectedWeb() && !cwOnBrowser) {
    const hasTizen = typeof win.tizen !== 'undefined' || /tizen|samsung/.test(ua)
    return hasTizen ? 'tv_samsung' : 'tv_xboxone'
  }
  return 'tv_generic'
}

export const getDeviceType = (): DeviceType => {
  switch (getConnectedDevicePlatform()) {
    case 'tv_generic':
      return 'web_browser'
    case 'tv_samsung':
      return 'tv_samsung'
    case 'tv_xboxone':
      return 'tv_xboxone'
  }
}

// check if tizen
export const getDeviceIsTizen = (): boolean => {
  return getConnectedDevicePlatform() === 'tv_samsung'
}

// check if xbox
export const getDeviceIsXbox = (): boolean => {
  return getConnectedDevicePlatform() === 'tv_xboxone'
}
// check if browser is safari (exclude all other browsers). get true/false with regex
export const getDeviceIsSafari = (): boolean => {
  const device_type = getDeviceType()
  // Only browsers can be classified as safari
  if (device_type !== 'web_browser') {
    return false
  }
  const ua = getUserAgent().toLowerCase()
  const safariRegex = /^((?!chrome|android|chromium|edg).)*safari\//gim
  return safariRegex.test(ua)
}

export const getDeviceName = () => {
  const userAgent = getUserAgent()

  if (/xbox/.test(userAgent.toLowerCase())) {
    return 'Xbox'
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS Device'
  } else if (/Macintosh|Mac OS X/.test(userAgent)) {
    return 'Mac'
  } else if (/Windows/.test(userAgent)) {
    return 'Windows PC'
  } else if (/Android/.test(userAgent)) {
    return 'Android Device'
  } else if (/tizen|samsung/.test(userAgent.toLowerCase())) {
    return 'Samsung TV'
  } else {
    return 'Unknown Device'
  }
}

export type DeviceCategory = 'console' | 'tv' | 'tablet' | 'mobile' | 'desktop'
export const getDeviceCategory = () => {
  switch (getDeviceName()) {
    case 'Xbox':
      return 'console'
    case 'Samsung TV':
      return 'tv'
    case 'iOS Device':
    case 'Android Device':
      return window?.innerWidth > 768 ? 'tablet' : 'mobile'
    case 'Mac':
    case 'Windows PC':
    default:
      return 'desktop'
  }
}

export const getDeviceId = (): string => {
  const currentDeviceId = window?.localStorage?.getItem(DEVICE_ID_KEY)
  let device_id: string = currentDeviceId ?? generateDeviceId()
  if (!currentDeviceId) {
    window.localStorage.setItem(DEVICE_ID_KEY, device_id)
  }
  return device_id
}

export const getCurrentDevice = (): CurrentDevice => ({
  navigator: getNavigator(),
  user_agent: getUserAgent(),
  device_id: getDeviceId(),
  device_name: getDeviceName(),
  device_type: getDeviceType(),
  device_platform: getConnectedDevicePlatform(),
  device_is_safari: getDeviceIsSafari(),
})

export const isMobileOrTablet = () => {
  try {
    const ua = UAParser(getUserAgent())
    const deviceType = ua.device.type
    return ua.device?.type === 'mobile' || deviceType === 'tablet'
  } catch {
    return false
  }
}

// AdInfo

// LAT acronym for "Limit Ad Tracking" (user has opted out of personalized ads)
enum AdIsLat {
  TRUE = '1',
  FALSE = '0',
}
type AdInfo = {
  did: string
  is_lat: AdIsLat
  device_make: string
  device_model: string
  platform: string
  osversion: string
}

// HELEPRS
const getWebAdInfo = (): AdInfo => {
  const ua = createUAParser()
  const device = ua.getDevice()
  const os = ua.getOS()
  return {
    platform: os?.name ?? 'Web',
    did: getDeviceId(),
    is_lat: AdIsLat.FALSE,
    device_make: device?.vendor ?? 'unknown',
    device_model: device?.model ?? 'unknown',
    osversion: os.version ?? 'unknown',
  }
}

const getXboxAdInfo = async (nativeAppVersion: string): Promise<AdInfo> => {
  const xbox = await getXboxInfo()
  const adInfo: AdInfo = {
    did: xbox.advertisingId || getDeviceId(),
    is_lat: xbox.isLAT ? AdIsLat.TRUE : AdIsLat.FALSE,
    platform: 'Windows', // always Windows
    device_make: 'Microsoft', // always Microsoft
    device_model: xbox.deviceModel,
    osversion: '10.0', // always 10.0 (Windows NT 10.0)
  }
  return adInfo
}

const getTizenVersion = () => {
  const ua = window.navigator.userAgent
  if (!ua) {
    return null
  }
  const match = /Tizen (\d+(\.\d+)?)/.exec(ua)
  return match ? match[1] : null
}
const getTizenAdInfo = async (): Promise<AdInfo> => {
  const tizenVersion = getTizenVersion()
  const adInfo: AdInfo = {
    did: getDeviceId(),
    is_lat: AdIsLat.TRUE,
    platform: 'Tizen',
    device_make: 'Samsung',
    device_model: 'SMART-TV',
    osversion: tizenVersion || 'unknown',
  }
  try {
    const ti = await import('tizen-tv-webapis')
    const getIsPermissionsError = (error: any) => {
      if (error && typeof error?.message === 'string') {
        return error.message.toLowerCase().indexOf('undefined') === -1
      }
      return false
    }

    // is_lat
    try {
      adInfo.is_lat = ti?.adinfo?.isLATEnabled() ?? true ? AdIsLat.TRUE : AdIsLat.FALSE
    } catch (error: any) {
      // ignore 'undefined' errors (See https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references/adinfo-api.html#Methods)
      if (getIsPermissionsError(error)) {
        // log
        Logger.of('AdInfo.getTizenAdInfo').warn(`ERROR @ ti.adinfo.isLATEnabled() for is_lat: `, error)
        // Error, such as a missing privilege.
        // Client app likely needs force-updated
      }
    }

    // did
    try {
      adInfo.did = ti?.adinfo?.getTIFA() ?? getDeviceId()
    } catch (error: any) {
      if (getIsPermissionsError(error)) {
        Logger.of('AdInfo.getTizenAdInfo').warn(`ERROR @ ti.adinfo.getTIFA() for did: `, error)
      }
    }

    // device_make
    try {
      adInfo.device_make = (ti as any)?.productinfo?.getLicensedVendor() ?? 'Samsung'
    } catch (error: any) {
      if (getIsPermissionsError(error)) {
        Logger.of('AdInfo.getTizenAdInfo').warn(`ERROR @ ti.productInfo.getLicensedVendor() for device_make: `, error)
      }
    }

    // device_model
    try {
      adInfo.device_model = ti?.productinfo?.getRealModel() ?? 'SMART-TV'
    } catch (error: any) {
      if (getIsPermissionsError(error)) {
        Logger.of('AdInfo.getTizenAdInfo').warn(`ERROR @ ti.productinfo.getModel() for device_model: `, error)
      }
    }
  } catch (_) {
    // Do nothing
  }
  return adInfo
}

const useDeviceInfo = (): DeviceInfo => {
  const { device_id, device_name, device_type } = getCurrentDevice()
  return useState<DeviceInfo>({
    device_id: useLocalStorage<string>(DEVICE_ID_KEY, device_id)[0],
    device_name,
    device_type,
  })[0]
}

export default useDeviceInfo
