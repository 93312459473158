import { VisualStudioNativeMessage, WindowWithChromeWebviewPost, WindowWithWebview1 } from './types'

const hasWebview2Notify = (window: any): window is WindowWithChromeWebviewPost => {
  return !!window.chrome?.webview?.postMessage
}

const hasWebview1Notify = (window: any): window is WindowWithWebview1 => {
  return typeof window.external !== 'undefined' && 'notify' in window.external
}

export const notifyXbox = (data: string | VisualStudioNativeMessage) => {
  // if (!isNotInProd()) {
  //   return
  // }
  const str = typeof data === 'string' ? data : JSON.stringify(data)
  if (hasWebview2Notify(window)) {
    try {
      window.chrome.webview.postMessage(str)
    } catch (_) {}
  } else if (hasWebview1Notify(window)) {
    try {
      ;(window as WindowWithWebview1).external.notify(str)
    } catch (_) {}
  }
}

export const redirectXboxConsole = () => {
  if (typeof window === 'undefined') {
    return
  }
  const hasNotify = hasWebview2Notify(window) || hasWebview1Notify(window)
  // if (!hasNotify || !isNotInProd()) {
  //   return
  // }
  const messages = [
    ``,
    `Webview2's console is no longer redirected to Visual Studio.`,
    `You can remotely debug this Webview2 app AND view the console output in the Edge browser on your Windows machine by following these instructions:`,
    `https://learn.microsoft.com/en-us/microsoft-edge/webview2/how-to/remote-debugging-xbox#attach-devtools-remotely-to-an-xbox-webview2-winui-2-uwp-app`,
    ` `,
  ]
  messages.forEach((message) => notifyXbox(message))
}
