import { AuthProfileOutput, CMSRegionTeam, ISO8601 } from '@grandstand/presentation-models'
import { getDeviceCategory } from '../../hooks/useDeviceInfo'
import currentUserStore from '../../newPackages/StorageProviders/currentUserStore'
import { TealiumPaymentMethod, TealiumUniversalParams } from './types'

export const getDaysBetweenDates = (startDate?: ISO8601 | null, endDate?: ISO8601 | null): number => {
  if (!startDate || !endDate) {
    return 0
  }
  const start = new Date(startDate)
  const end = new Date(endDate)
  return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))
}

export const paymentMethodForPurchase = (paymentMethod?: string): TealiumPaymentMethod => {
  const method = !paymentMethod ? 'credit' : paymentMethod.toLowerCase()
  return method.indexOf('paypal') > -1 ? 'paypal' : 'credit'
}

export const getTeamAccess = (nextUser?: AuthProfileOutput | AuthProfileOutput): string | undefined => {
  const currentUser = nextUser ? (nextUser as AuthProfileOutput) : currentUserStore.getItem()
  if (currentUser === undefined) {
    return undefined
  }
  const regionalTeams = currentUser.profile.region.all_regional_teams
  const teams = regionalTeams.filter((team) => Boolean(team?.DTCavailable)).map((team) => team.team)
  return teams.join('|')
}

export const getUniversalParams = (): TealiumUniversalParams => {
  const currentUser = currentUserStore.getItem()
  const analytics = currentUser?.analytics?.tealium
  const device_category = getDeviceCategory()
  if (!analytics) {
    return { device_category }
  }
  return {
    ...analytics,
    device_category,
    fav_team: analytics.fav_team?.join(',') ?? undefined,
  }
}

export const getFavTeamForTealium = (currentUser: AuthProfileOutput, teamIds: string[]) => {
  const allTeams: CMSRegionTeam[] = currentUser.profile?.region.all_regional_teams ?? []
  if (!teamIds.length || !allTeams.length) {
    return ''
  }
  const teamNames = teamIds.reduce((acc, teamId) => {
    const team = allTeams.find((team: CMSRegionTeam) => team?.sport_radar_id === teamId)
    if (team) {
      return [...acc, team.team]
    }
    return acc
  }, [] as string[])
  return teamNames?.length > 0 ? teamNames.join(',') : ''
}
