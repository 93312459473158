import { WindowWithWebview1 } from './types'

export const getXboxInfoWebview1 = () => {
  const win = window as WindowWithWebview1
  const version = win?.Windows?.ApplicationModel?.Package?.current?.id?.version

  return {
    appVersion: version ? `${version.major}.${version.minor}.${version.build}.${version.revision}` : 'unknown',
    advertisingId: win?.Windows?.System?.UserProfile?.AdvertisingManager?.advertisingId,
  }
}
